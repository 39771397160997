<template>
      <v-app class>
        <div>
          <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>Departments</h1>
                </div>
               <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2"/>
               </div>
              </div>
             <v-data-table
              loading-text="Loading departments... Please wait"
              :loading="!departments.length"
              :headers= 'headers'
              :items="departments"
             >
                <template v-slot:item.dateAdded="{ item }" >
                  {{item.dateAdded | formatDate}}
                </template>
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                      <Button :btnType="'Submit'" :label="'Edit'" @onClick="edit(item)" class="mr-2"/>
                      <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" />
                    </div>
              </template>

             </v-data-table>

              <!-- Dialog for edit  -->
              <Dialog ref="editModal" :title="'Edit'" :width="600">
                <v-row>
                  <TextField v-model="formData.name" :label="'Document Name'"/>
                </v-row>
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Update'" @onClick="update" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="cancel('editModal')" />
                </template>
              </Dialog>

              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteDepartment" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>

              <!-- add modal  -->
              <Dialog ref="addModal"  :title="'Add Department'" :width="600">
                <v-row>
                  <v-col cols="12">
                    <v-form ref="department" v-model="valid">
                    <v-col cols="12">
                        <TextField v-model="formData.name" :label="'Name'"/>
                    </v-col>
                  </v-form>
                  </v-col>
                </v-row>
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Submit'" :disabled="!valid" :isLoading="isSaving" @onClick="dialog = true" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="closeAddModal" />
                </template>
              </Dialog>

              <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="createDepartment"/>

            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { UPDATE_DEPARTMENT, FETCH_DEPARTMENT, DELETE_DEPARTMENT, CREATE_DEPARTMENT } from '@/store/action-type'
export default {
  components: {
    TextField,
    Button,
    Dialog,
    ConfirmationDialog
  },
  data () {
    return {
      dialog: false,
      search: '',
      formData: {
        name: '',
        id: ''
      },
      valid: false,
      isSaving: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Added By',
          value: 'addedBy'
        },
        {
          text: 'Date Added',
          value: 'dateAdded'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy'
        },
        {
          text: 'Modified Date',
          value: 'lastModifiedDate'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    departments () {
      return this.$store.getters.departments
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    edit (item) {
      this.$refs.editModal.openModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.openModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    deleteDepartment () {
      this.$store.dispatch(DELETE_DEPARTMENT, this.formData).then((result) => {
        this.showAlertMessage('Deparment deleted successful', 'success')
      }).catch(error => {
        console.log(error.message)
        this.showAlertMessage('Deparment delete failed', 'error')
      }).finally(() => {
        this.$refs.deleteModal.closeModal()
      })
    },
    createDepartment () {
      this.isSaving = true
      this.$store
        .dispatch(CREATE_DEPARTMENT, this.formData)
        .then(() => {
          this.showAlertMessage('Department created successfully', 'success')
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Deparment creation failed', 'error')
        })
        .finally(() => {
          this.isSaving = false
          this.closeAddModal()
          this.dialog = false
        })
    },
    update () {
      this.$store.dispatch(UPDATE_DEPARTMENT, this.formData).then((result) => {
        this.showAlertMessage('Deparment Updated successful, Awaiting Approval', 'success')
      }).catch(error => {
        console.log(error.message)
        this.showAlertMessage('Deparment Update Failed', 'error')
      }).finally(() => {
        this.$refs.editModal.closeModal()
      })
    },
    Add () {
      this.$refs.addModal.openModal()
    },
    closeAddModal () {
      this.$refs.department.reset()
      this.$refs.addModal.closeModal()
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.closeModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.closeModal()
      }
    }

  },
  mounted () {
    this.$store
      .dispatch(FETCH_DEPARTMENT)
      .then(() => {})
      .catch((error) => {
        if (error) {
          this.showAlertMessage('There are no items to display', 'warning')
        }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
